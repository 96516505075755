@import '../../utils.scss';

.app-switcher-select {
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 600;
  background-color: $primary;
  outline: none;

  & option {
    font-weight: 600;
  }
}
