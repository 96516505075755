@import '../../utils.scss';

.sidebar {
  width: 227px;
  min-height: 100vh;
  background-color: $color-dark-one;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-dark-one;
  }

  @include responsive($mobileL) {
    display: none;
  }

  &-logo {
    position: relative;
    width: 100%;
  }

  &-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 134.52%;
    color: #ffffff;
    margin: 2.5rem;

    // background: -webkit-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: -o-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: -moz-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: linear-gradient(45deg, #20dacf 1%, #ff3a81);

    -webkit-text-fill-color: transparent;
  }
  &-menu {
    margin-bottom: 2.625rem;
    &-header {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 134.52%;
      color: #ffffff;
      padding-left: 1.25rem;
      img {
        margin-right: 0.625rem;
        width: 18px;
        height: 18px;
      }
    }
    ul {
      padding-left: 0;
      margin: 0;
      li {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
        list-style-type: none;
        padding: 0.5rem 0 0.5rem 3.25rem;
        cursor: pointer;

        &.active {
          border-left: 6px solid $primary;
          color: $primary;
          padding-left: 2.875rem !important;
          transition: all 0.2s;

          &:hover {
            background-color: $color-light-one;
            color: $primary;
            text-decoration: none;
          }
        }
        &:hover {
          color: $primary;
          background-color: $color-light-one;
        }
      }
    }
  }
}
