@import '../../utils.scss';
.user-profile {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;

  @include responsive($mobileL) {
    display: inline-block;
    right: 8vw;
  }

  @include responsive($mobile) {
    @include center;
    bottom: 30px;
  }

  &-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134.52%;
    display: flex;
    align-items: center;
    color: #ffffff;

    @include responsive($mobileL) {
      display: none;
    }

    @include responsive($mobile) {
      display: none;
    }
  }
  &-avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-left: 1rem;
    cursor: pointer;

    &-desktop {
      @include responsive($mobileL) {
        display: none;
      }
    }

    &-mobile {
      display: none;
    }
  }
  &-caret {
    margin-left: 0.5rem;
    cursor: pointer;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    &.up {
      transform: rotate(180deg);
    }

    @include responsive($mobileL) {
      display: none;
    }
  }
  &-dropdown {
    position: absolute;
    width: 200px;
    float: right;
    right: 0;
    top: 50px;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    @include responsive($mobileL) {
      display: none;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
    ul {
      list-style-type: none;
      text-align: right;
      padding: 0;
      margin: 0;
      li {
        background-color: $color-dark-one;
        border-left: solid 1px#0ef1db;
        border-right: solid 1px#0ef1db;
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-top: solid 1px#0ef1db;
        }
        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-bottom: solid 1px#0ef1db;
        }
        &:hover {
          background-color: #0ef1dabf;
        }
        a {
          color: $color-dark-one;
          color: white;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 134.52%;
        }
      }
    }

    &-inner {
      padding: 0.25rem 1rem;
    }
  }
}
