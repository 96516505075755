@import '../../utils.scss';

.mobileMenu {
  position: relative;
  width: 100%;
  top: 80px;
  display: grid;
  left: -10vw;

  justify-content: center;

  &-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 134.52%;
    color: #ffffff;
    padding-left: 2.5rem;
    img {
      margin-right: 0.625rem;
      width: 18px;
      height: 18px;
    }
  }

  li {
    display: block;
    color: $color-light-two;
    font-size: 20px;

    &:active {
      color: $primary;
    }
  }

  &-overlay {
    background-color: $color-dark-one;
    position: fixed;
    overflow-y: scroll;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &-x {
    position: relative;
    float: right;
    top: 12vw;
    right: 12vw;

    cursor: pointer;
  }

  &-burger {
    display: none;

    @include responsive($mobileL) {
      position: absolute;
      top: 8vw;
      right: 9vw;
      display: inline-block;
      cursor: pointer;
    }
  }
}

div.mobileMenu-burger {
  position: relative !important;
  right: 17px;
  top: -5px;
  float: right;

  @include responsive($mobile) {
    top: -4px;
  }
}
