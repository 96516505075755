@import '../../utils.scss';

.header-container {
  margin-left: 227px;
  padding: 1.5rem 1.5rem;
  display: grid;
  row-gap: 1.5rem;
  @include responsive($mobileL) {
    margin-left: 0;
  }
  &-header {
    margin-left: 227px;
    padding: 3.5rem 13px 0 1.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 99%;
    row-gap: 20px;
    @include responsive($mobileL) {
      padding-top: 25px;
      margin-left: 0;
    }
    &-top {
      display: grid;
      grid-template-columns: 1fr 0fr;
      @include responsive($mobileL) {
        margin-top: 20px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 10px;
      }
    }
  }
}
