@import './utils.scss';

:root {
  --toastify-color-success: #0ef1db !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background: $site-background;
  scroll-behavior: smooth;
  color: #fff;
}

a {
  color: $primary;
  text-decoration: none;

  // -webkit-user-select: none; /* Safari */
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* IE10+/Edge */
  // user-select: none; /* Standard */

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

::-moz-selection {
  /* Code for Firefox */
  // color: $primary;
  background: $primary;
}

::selection {
  // color: $primary;
  background: $primary;
  color: black;
}

.cursor {
  &-default {
    cursor: default;
  }

  &-pointer {
    cursor: pointer;
  }
}

.color {
  &-primary {
    color: $primary;
  }

  &-gray10 {
    color: $color-light-two;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
}

.modal-show {
  opacity: 1 !important;
  z-index: 50 !important;
  background-color: rgba(52, 57, 71, 0.7) !important;
}

.h {
  &-100vh {
    height: 100vh;
  }
}

.xy-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.opacity {
  &-50 {
    opacity: 0.5;
  }
}
