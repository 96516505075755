@import '../../utils.scss';

.button {
  background: $color-dark-one;
  position: relative;
  width: 300px;
  height: 56px;
  float: right;
  width: 100%;
  border: none !important;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  color: white;
}

.myFilesContainer {
  padding: 16px 16px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
}

.dashboard-overview {
  margin-left: 227px;
  padding: 1.5rem 1.5rem;
  display: grid;
  row-gap: 1.5rem;

  &-streaming-stats {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    overflow-x: auto;

    &-spinner {
      &-wrapper {
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
  }
}

.table-view {
  width: inherit;
  padding: 12px;
  background: #1d2025;
  border-radius: 10px;
}

.table-view.files {
  overflow-x: auto;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
}

.copyright-table {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  color: white;
  font-size: 14px;

  td {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 768px) {
      max-width: 250px;
    }
  }

  @media (max-width: 768px) {
    table-layout: auto;
  }
}

.copyright-table thead tr:first-child {
  border-bottom: 1px solid $primary;
}

.copyright-table tbody tr:nth-child(odd) {
  background: #272931;
}

.copyright-table tbody tr:last-child {
  border-bottom: 1px solid $primary;
}

.dspInputGroup {
  position: relative;
  width: 300px;
  height: 56px;

  float: right;

  background: #1d2025;
  border: none !important;
  border-radius: 10px;

  box-shadow: none;

  @include responsive($mobile) {
    width: 326px;
    height: 56px;
    left: 24px;
    top: 770px;
  }
}

.dspInputGroup.admin-search {
  float: unset;
  width: 100%;

  @include responsive($mobile) {
    left: 0;
    top: 0;
  }
}

.dspInput {
  background-color: #1d2025;
  border: none;
  height: 100%;
  border-radius: 10px;
  color: #8e8f92;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #1d2025;
    color: #8e8f92;
  }
}

.dspSearchIcon {
  position: relative;
  top: 1px;
  left: 7px;
}

.dspInputText {
  background-color: #1d2025;
  border: none;
  border-radius: 10px;
}

.dspInputAddon {
  background-color: #1d2025 !important;
  border: none;
  border-radius: 10px;
}

.inputIcon {
  width: 1em;
  filter: contrast(0.05);
}

.interMediumInput {
  font-family: Inter-Medium !important;
  color: white;
  opacity: 1;
}

.interMediumPaddingTop {
  padding-top: 20px !important;
}
.disabledAddon {
  opacity: 0.5;
}

.inputIcon {
  width: 1em;
  filter: contrast(0.05);
}

.table-action-link {
  text-decoration: underline;
  color: #98a5e7;
  cursor: pointer;

  &:hover {
    color: #ccc;
    text-decoration: underline;
  }
}

// Header css

.header {
  height: 150px;

  @media (max-width: 768px) {
    height: 100px;
  }
}

.dDecoration1 {
  position: absolute;
  width: 110px;
  height: 110px;
  margin-left: 5%;
  top: 70px;

  border: 4px dashed #2c3036;
  box-sizing: border-box;
  border-radius: 16px;
  transform: rotate(-16.75deg);

  padding: 29px 0 0 41px;

  .dd1a {
    position: relative;
    width: 22px;
    height: 43px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 134.52%;

    color: #0ef1db;

    transform: rotate(-1deg);
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.dShader {
  position: absolute;
  width: 30%;
  height: 280px;
  top: 0px;

  margin-left: -20px;

  z-index: 900;

  background: linear-gradient(0deg, #343947 -12%, rgba(52, 57, 71, 0) 88%);
  @media (max-width: 768px) {
    display: none;
  }
}

.dDecoration3 {
  position: absolute;
  width: 146px;
  height: 205px;
  left: 33%;
  top: 30px;

  background: #1d2025;
  border-radius: 16px;
  transform: rotate(10deg);

  .dd3star {
    position: absolute;
    right: 14px;
    top: 14px;
    transform: rotate(-4.77deg);
  }

  .dd3group {
    position: absolute;
    top: 89px;
    left: 53px;
    transform: rotate(-4.77deg);
  }
  @media (max-width: 768px) {
    display: none;
  }
}
